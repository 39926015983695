import React, { useEffect } from "react";
import { navigate } from "gatsby-link";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return <></>;
};

export default NotFoundPage;
